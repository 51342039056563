import { createRouter, createWebHistory } from "vue-router";

import AboutPage from "../components/Pages/AboutPage";
import AccountPage from "../components/Pages/AccountPage";
import ApplyPage from "../components/Pages/ApplyPage";
import CarLoanPage from "../components/Pages/CarLoanPage";
import ContactUsPage from "../components/Pages/ContactUsPage";
import ErrorPage from "../components/Pages/ErrorPage";
import FaqPage from "../components/Pages/FaqPage";
import HealthSector from "../components/Pages/HealthSector";
import HomeOnePage from "../components/Pages/HomeOnePage";
import HomeThreePage from "../components/Pages/HomeThreePage";
import HomeTwoPage from "../components/Pages/HomeTwoPage";
import LoginPage from "../components/Pages/LoginPage";
import MyAccountPage from "../components/Pages/MyAccountPage";
import PersonalLoanPage from "../components/Pages/PersonalLoanPage";
import PricingPage from "../components/Pages/PricingPage";
import PrivacyPolicyPage from "../components/Pages/PrivacyPolicyPage";
import ProjectDetailsPage from "../components/Pages/ProjectDetailsPage";
import ProjectPage from "../components/Pages/ProjectPage";
import RecoverPasswordPage from "../components/Pages/RecoverPasswordPage";
import Referral from "../components/Pages/Referral";
import RegisterPage from "../components/Pages/RegisterPage";
import ServicesDetailsPage from "../components/Pages/ServicesDetailsPage";
import ServicesPage from "../components/Pages/ServicesPage";
import TeamPage from "../components/Pages/TeamPage";
import TermsOfServicePage from "../components/Pages/TermsOfServicePage";
import TestimonialsPage from "../components/Pages/TestimonialsPage";

const routes = [
    { path: "/", name: "HomeOnePage", component: HomeOnePage, props: true },
    { path: "/home-two", name: "HomeTwoPage", component: HomeTwoPage },
    { path: "/home-three", name: "HomeThreePage", component: HomeThreePage },
    { path: "/about", name: "AboutPage", component: AboutPage },
    { path: "/personal-loan", name: "PersonalLoanPage", component: PersonalLoanPage },
    { path: "/car-loan", name: "CarLoanPage", component: CarLoanPage },
    { path: "/services", name: "ServicesPage", component: ServicesPage },
    { path: "/partners/calculator/:sector", name: "Calculator", component: HealthSector },
    {
        path: "/service-details",
        name: "ServicesDetailsPage",
        component: ServicesDetailsPage,
    },
    { path: "/projects", name: "ProjectPage", component: ProjectPage },
    {
        path: "/project-details",
        name: "ProjectDetailsPage",
        component: ProjectDetailsPage,
    },
    { path: "/team", name: "TeamPage", component: TeamPage },
    { path: "/open-account", name: "AccountPage", component: AccountPage },
    // { path: "/apply-loan", name: "ApplyPage", component: ApplyPage },
    {
        path: "/apply-loan",
        name: "ApplyPage",
        component: ApplyPage,
        props: true,
    },
    { path: "/pricing", name: "PricingPage", component: PricingPage },
    { path: "/faq", name: "FaqPage", component: FaqPage },
    {
        path: "/testimonials",
        name: "TestimonialsPage",
        component: TestimonialsPage,
    },
    { path: "/my-account", name: "MyAccountPage", component: MyAccountPage },
    { path: "/login", name: "LoginPage", component: LoginPage },
    { path: "/register", name: "RegisterPage", component: RegisterPage },
    {
        path: "/recover-password",
        name: "RecoverPasswordPage",
        component: RecoverPasswordPage,
    },
    {
        path: "/terms-of-service",
        name: "TermsOfServicePage",
        component: TermsOfServicePage,
    },
    {
        path: "/privacy-policy",
        name: "PrivacyPolicyPage",
        component: PrivacyPolicyPage,
    },
    {
        path: "/referral",
        name: "Referral",
        component: Referral,
    },
    { path: "/:pathMatch(.*)*", name: "ErrorPage", component: ErrorPage },
    { path: "/contact", name: "ContactUsPage", component: ContactUsPage },
];

const router = createRouter({
    history: createWebHistory(),
    linkExactActiveClass: "active",
    routes,
    scrollBehavior() {
        return { top: 0, behavior: "smooth" };
    },
});

export default router;
